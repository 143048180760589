<template>
  <div class="stock bg-gray-2">
    <b-row class="mx-0 h-100">
      <b-col
        cols="12"
        lg="6"
        class="px-0 border-right border-right-light border-right-line"
      >
        <header-page :class-name="'size18 text-dark fw-bold-800 ml-0 px-2'">
          Stok
        </header-page>
        <b-container class="mt-2">
          <h5 class="fw-bold-800 size16 text-dark px-2">
            Pengaturan Stok
          </h5>
          <b-card
            v-for="(stock, index) in stocks"
            v-if="checkPermission(stock.permissions)"
            :key="index"
            class="p-2 m-1 border-12 card__stock"
            no-body
            style="cursor: pointer;"
            @click="navigationPage(stock.path)"
          >
            <div class="d-flex align-items-center">
              <div
                class="d-flex justify-content-center align-items-center rounded-circle"
                :class="stock.class"
              >
                <b-img
                  :src="require(`@/assets/images/icons/${stock.image}.svg`)"
                  blank-color="#ccc"
                  width="18"
                  alt="placeholder"
                />
              </div>
              <div class="ml-1">
                <h5 class="text-dark size14 fw-bold-800">
                  {{ stock.name }} {{ stock.name === 'Stok Opname' ? `(${$store.state.stockOpname.totalNotApproved || (0)})` : '' }}
                </h5>
                <h6 class="text-dark text-darken-4 fw-bold-500 size12">
                  {{ stock.description }}
                </h6>
              </div>
            </div>
          </b-card>
        </b-container>
        <b-container 
          class="mt-2"
          v-if="checkPermission('approve/reject stock opname')"
        >
          <h5 class="fw-bold-800 size16 text-dark px-2">
            Menunggu Persetujuan
          </h5>
          <b-card
            v-for="(approval, index) in approvals"
            :key="index"
            class="p-2 m-1 border-12 card__stock"
            no-body
            style="cursor: pointer;"
            @click="$router.push({ name: 'stock.opname', query: { q: 'approval' } })"
          >
            <div class="d-flex align-items-center">
              <div
                class="d-flex justify-content-center align-items-center rounded-circle"
                :class="approval.class"
              >
                <b-img
                  :src="require(`@/assets/images/icons/${approval.image}.svg`)"
                  blank-color="#ccc"
                  width="18"
                  alt="placeholder"
                />
              </div>
              <div class="ml-1">
                <h5 class="text-dark size14 fw-bold-800">
                  {{ approval.name }}
                </h5>
                <h6 class="text-dark text-darken-4 fw-bold-500 size12">
                  {{ approval.description }}
                </h6>
              </div>
            </div>
          </b-card>
        </b-container>
      </b-col>
      <b-col
        cols="12"
        lg="6"
        class="px-0"
      >
        <!-- Header -->
        <div
          class="stock__filter--container w-100 bg-white d-flex align-items-center"
          style="height: 83.19px;"
        >
          <b-button-group class="h-100">
            <b-button
              v-for="(item, index) in listFilter"
              :id="`button--filter${index}`"
              :key="`${item}-${index}`"
              class="filter--button"
              :class="{ 'active' : item == filter }"
              @click="filter = item"
            >
              {{ item }}
            </b-button>
          </b-button-group>
        </div>
        <!-- Tab Stok Menipis / Opname / Terlaris -->
        <b-container class="mt-2 stock__product--container">
          <div
            v-if="isLoading"
            class="text-center"
          >
            <b-spinner variant="primary" />
          </div>
          <b-card
            v-for="(item, index) in outOfStocks.data"
            :key="index"
            class="p-0 m-1 border-12 card__stock"
            no-body
            v-if="!isLoading"
          >
            <div class="d-flex">
              <div>
                <div
                  v-if="filter == 'Stok Menipis'"
                  class="image__background"
                  :style="`background-image: url(${item.product && item.product.image_url || ''});position:relative;`"
                >
                  <div
                    v-if="item.stock <= 0"
                    class="image__outstock"
                    :style="`background-image: url(${require('@/assets/images/icons/outofstock.svg')});`"
                  />
                </div>
                <div
                  v-else-if="filter == 'Sedang Stok Opname'"
                >
                  <div
                    class="image__background"
                    v-for="(stockItem, itemIndex) in item.items" :key="itemIndex"
                    :style="`background-image: url(${stockItem.product && stockItem.product.image_url || ''});position:relative;`"
                  >
                    <div
                      v-if="stockItem.stock <= 0"
                      class="image__outstock"
                      :style="`background-image: url(${require('@/assets/images/icons/outofstock.svg')});`"
                    />
                  </div>
                </div>
                <div
                  v-else
                  class="image__background"
                  :style="`background-image: url(${item.image_url || ''});position:relative;`"
                />
              </div>
              <div class="d-flex flex-column w-100 px-1 justify-content-center">
                <div class="d-flex justify-content-between" v-if="filter == 'Stok Menipis'">
                  <h5 class="text-dark size14 fw-bold-800">
                    {{ item.product && item.product.name || '' }}
                  </h5>
                  <h5 class="text-dark fw-bold-800 size14">
                    {{ item.product && item.product.standard_price || 0 | formatAmount }}
                  </h5>
                </div>
                <div class="d-flex justify-content-around h-100 flex-column" v-else-if="filter == 'Sedang Stok Opname'">
                  <div class="d-flex justify-content-between flex-column" v-for="(stockItem, itemIndex) in item.items" :key="itemIndex">
                    <div class="d-flex justify-content-between">
                      <h5 class="text-dark size14 fw-bold-800" >
                        {{ stockItem.product && stockItem.product.name || '' }}
                      </h5>
                      <h5 class="text-dark fw-bold-800 size14">
                        {{ stockItem.product && stockItem.product.standard_price || 0 | formatAmount }}
                      </h5>
                    </div>

                    <h6 class="text-dark text-darken-5 fw-bold-500 size12 mb-0">
                      <span>
                        Tersisa {{ stockItem.qty }}
                        {{ stockItem.product && stockItem.product.uom && stockItem.product.uom.name ? stockItem.product.uom.name : '' }} 
                        • 
                        {{ item.warehouse && item.warehouse.name ? item.warehouse.name : '' }}   
                      </span>
                    </h6>
                  </div>
                </div>
                <div class="d-flex flex-column w-100 px-1 justify-content-center" v-else>
                  <div class="d-flex justify-content-between">
                    <h5 class="text-dark size14 fw-bold-800">
                      {{ item.name || '' }}
                    </h5>
                    <h5 class="text-dark fw-bold-800 size14">
                      {{ item.standard_price || 0 | formatAmount }}
                    </h5>
                  </div>
                </div>
                <div>
                  <h6 class="text-dark text-darken-5 fw-bold-500 size12 mb-0" v-if="filter == 'Stok Menipis'">
                    Tersisa {{ item.stock }} {{ item.product && item.product.unit || '' }} • {{ item.place || '' }}
                  </h6>
                  <!-- <h6 class="text-dark text-darken-5 fw-bold-500 size12 mb-0" v-else-if="filter == 'Sedang Stok Opname'">
                    <span v-for="(stockItem, itemIndex) in item.items" :key="itemIndex">
                      Tersisa {{ stockItem.qty }}
                      {{ stockItem.product && stockItem.product.uom && stockItem.product.uom.name ? stockItem.product.uom.name : '' }} 
                      • 
                      {{ item.warehouse && item.warehouse.name ? item.warehouse.name : '' }}   
                    </span>
                  </h6> -->
                </div>
              </div>
            </div>
          </b-card>
        </b-container>
        <div class="pagination__product">
          <b-row class="mx-0 align-items-center">
            <b-col v-if="filter == 'Stok Menipis'">
              <pagination
                class="mb-0"
                :data="outOfStocks"
                :limit="4"
                align="center"
                @pagination-change-page="getOutOfStocks"
              />
            </b-col>
            <b-col v-else-if="filter == 'Sedang Stok Opname'">
              <pagination
                class="mb-0"
                :data="outOfStocks"
                :limit="4"
                align="center"
                @pagination-change-page="getStockOpnames"
              />
            </b-col>
            <b-col v-else>
              <pagination
                class="mb-0"
                :data="outOfStocks"
                :limit="4"
                align="center"
                @pagination-change-page="getBestSeller"
              />
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <!-- <b-container class="px-0">
    </b-container> -->
  </div>
</template>

<script>
import {
  BContainer, BButton, BButtonGroup, BImg, BCard, VBToggle, BRow, BCol, BSpinner,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import HeaderPage from '@/components/HeaderPage.vue'

export default {
  title() {
    return 'Stok'
  },
  components: {
    BContainer,
    BButton,
    HeaderPage,
    BButtonGroup,
    BRow,
    BCol,
    BCard,
    BImg,
    BSpinner,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  setup() {
    return {
      checkPermission, successNotification, errorNotification,
    }
  },
  data() {
    return {
      listFilter: ['Stok Menipis', 'Sedang Stok Opname', 'Terlaris di Bulan Ini'],
      filter: 'Stok Menipis',
      comp: 'TableIncomeProduct',
      stocks: [
        {
          name: 'Stok Lokasi SKU',
          description: 'Stok yang ada di lokasi SKU secara keseluruhan',
          image: 'stok_gudang',
          class: 'stock__image--gudang',
          path: 'stock.gudang',
          permissions: ['list stok gudang' , 'list aktual stok gudang'],
        },
        {
          name: 'Stok Pemasok',
          description: 'Stok yang ada pada pemasok',
          image: 'stok_supplier',
          class: 'stock__image--supplier',
          path: 'stock.supplier',
          permissions: ['list stok supplier' , 'list aktual stok supplier'],
        },
        {
          name: 'Stok Opname',
          description: 'Stok ketersediaan yang disimpan perusahaan.',
          image: 'stok_opname',
          class: 'stock__image--opname',
          path: 'stock.opname',
          permissions: ['list stock opname', 'approve/reject stock opname'],
        },
        {
          name: 'Transfer Stok',
          description: 'Transfer stok dari lokasi SKU satu ke lokasi SKU lain.',
          image: 'stock_transfer',
          class: 'stock__image--opname',
          path: 'stock.transfer',
          permissions: 'transfer stok',
        },
      ],
      approvals: [
        // {
        //   name: 'Approval Produk Masuk (23)',
        //   description: 'Run a payroll outside of your regular pay schedule',
        //   image: 'approval',
        //   class: 'stock__image--approval',
        // },
        {
          name: 'Persetujuan Stok Opname',
          description: 'Persetujuan atas perubahan stok',
          image: 'approval',
          permissions: 'list stock opname',
          class: 'stock__image--approval',
        },
      ],
      outOfStocks: {},
      isLoading: false,
    }
  },
  computed: {
  },
  watch: {
    filter(value) {
      if (value === 'Stok Menipis') {
        this.getOutOfStocks()
      }
      if (value === 'Sedang Stok Opname') {
        this.getStockOpnames()
      }
      if (value === 'Terlaris di Bulan Ini') {
        this.getBestSeller()
      }
    },
  },
  created() {
    if (checkPermission('list stock opname')) {
      this.getStockOpname()
    }
    this.getOutOfStocks()
  },
  mounted() {
    if (this.$route.query.cta === 'produk_terlaris') {
      this.filter = 'Terlaris di Bulan Ini'
    }
    if (this.$route.query.cta === 'produk_menipis') {
      this.filter = 'Stok Menipis'
    }
  },
  methods: {
    getStockOpname() {
      this.$store.dispatch('stockOpname/getData', {
        uuid: '',
        params: {
          status: 'pending',
          per_page: 0,
        },
      }).then(result => {
        this.$store.commit('stockOpname/setTotalNotApproved', result.data.data.total)
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    getStockOpnames(page = 1) {
      this.isLoading = true
      this.$store.dispatch('stockOpname/getData', {
        uuid: '',
        params: {
          status: 'pending',
          per_page: 10,
          page,
        },
      }).then(result => {
        this.outOfStocks = result.data.data
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    getOutOfStocks(page = 1) {
      this.isLoading = true
      this.$store.dispatch('parentProduct/outOfStocks', {
        params: {
          page,
          per_page: 10,
        },
      }).then(result => {
        this.outOfStocks = result.data.data
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    getBestSeller(page = 1) {
      this.isLoading = true
      this.$store.dispatch('parentProduct/getBestSeller', {
        uuid: '',
        params: {
          page,
          per_page: 10,
        },
      }).then(result => {
        this.outOfStocks = result.data.data
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    navigationPage(pathName) {
      this.$router.push({ name: pathName })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
@import '../../assets/scss/variables/_variables.scss';

.vue2leaflet-map{
  &.leaflet-container{
    height: 350px;
  }
}

.stock {
  position: relative;
  // height: 100vh;

  .stock__product--container {
    height: 75vh;
    overflow-y: scroll;
  }

  .card__stock {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);

    .stock__image--gudang {
      width: 55px;
      height: 55px;
      background: #FEF9F5;
      box-shadow: inset 2px 2px 4px rgb(230 219 211 / 20%);
    }
    .stock__image--supplier {
      width: 55px;
      height: 55px;
      background: #FFF3FE;
      box-shadow: inset 2px 2px 4px rgba(232, 204, 232, 0.16);
    }
    .stock__image--opname {
      width: 55px;
      height: 55px;
      background: #E6F8FF;
    }
    .stock__image--approval {
      width: 55px;
      height: 55px;
      background: #E9F9ED;
    }
  }

  .image__background {
    width: 72.27px;
    height: 77px;
    border-radius: 12px 0px 0px 12px;
    background-size: cover;
    background-repeat: no-repeat;

    .image__outstock {
      width: 75px;
      height: 24px;
      background-repeat: no-repeat;
      position: absolute;
      top: -2px;
      left: -1px;
    }
  }

  .pagination__product {
    height: 80px;
    background: #FFFFFF;
    box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.06);
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 2rem;

    .page-item {
      .page-link {
        font-weight: 600;
        color: $secondary-2 !important;
        background-color: transparent;
      }
      &.active {
        .page-link {
          border-radius: 0;
          background-color: $light--1;
          font-weight: 600;
          color: $secondary-2 !important;
        }
      }
    }

    .page-item.prev-item ~ .page-item:nth-child(2) .page-link {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }

    .page-item.prev-item ~ .page-item:nth-last-child(2) .page-link {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    .page-item.next-item .page-link:active,
    .page-item.next-item .page-link:hover {
      background-color: transparent !important;
    }
  }

  .pagination__container {
    width: 100%;
    height: 80px;
    position: fixed;
    bottom: 0;
    background: #FFFFFF;
    box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.06);
    border: 1px solid #E4E5EC;

    .dropdown {

      button {
        font-size: 14px;
        color: $dark;
        font-weight: 600;

        &:hover {
          background-color: transparent;
        }
      }
    }

    small {
      color: $secondary-2;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .stock__filter--container {
    display: flex;
    justify-content: space-between;

    .filter--button {
      border: none !important;
      color: $light--2 !important;
      background-color: transparent !important;
      border-color: transparent !important;

      &.active {
        color: $secondary !important;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 50%;
          margin-left: 25%;
          border-radius: 4px 4px 0px 0px;
          border: 2px solid $secondary;
        }
      }

      &:hover {
        box-shadow: none !important;
      }
    }
  }
}
</style>
